import PromoList from "@/components/PromoList";
import React from "react";

const PromoList5 = () => {
  return (
    <div>
      <PromoList cardNumer={5} />
    </div>
  );
};

export default PromoList5;
